((FUNCTIONS) => {
	const a = 'is-active';
	const v = 'is-visible';

	// ＜ルート設定対応＞要素が空だったら削除
	const removeElmIfEmpty = () => {
		const $eleList = document.querySelectorAll('.js-checkEmptyElm');
		$eleList.forEach(ele => {
			if (!ele.textContent.length) {
				ele.remove();
			}
		});
	}
	// ＜ルート設定対応＞該当のリストにルート設定出力が0件の場合、指定する親ブロックごと削除する
	const removeBlockIfListEmpty = () => {
		const $eleList = document.querySelectorAll('.js-checkEmptyList');
		if ($eleList) {
			$eleList.forEach(ele => {
				if (!ele.getElementsByTagName('a').length) {
					ele.closest('.js-checkEmptyList-wrap').remove();
				}
			});
		}
	};

	const categorytagJSON = () => {
		const getJSONfilescategorytag = (_id) => {
			return `/ajax/news/${_id}.json`;
		};
		if(document.querySelectorAll('.js-categorytag').length) {
			document.querySelectorAll('.js-categorytag').forEach(function(val, index){
				fetch(getJSONfilescategorytag(val.querySelector('.rt_cf_ng_news_key').textContent)).then(function (data) {
					return data.json();
				}).then(function (data) {
					// console.log(data,'data');
					let CATEGORY = '';
					CATEGORY = `<span>${data.rt_bn_news_info_json[0].cf_n_category.cf_nc_name}</span>`;
					val.querySelector('.js-categorytag-label').insertAdjacentHTML('beforeend', CATEGORY);
					let TAG = '';
					if (data.rt_bn_news_info_json[0].cf_n_tags) {
						data.rt_bn_news_info_json[0].cf_n_tags.forEach(function(tagval, tagindex){
						// console.log(data.rt_bn_news_info_json[0].cf_n_tags,'aaaaaaa');
						// console.log(tagval, tagindex,'test');
						TAG += `<li><span class="__tag">${tagval.cf_nt_name_d}</span></li>`;
							// console.log(TAG,'testtag');
						});
						// console.log(TAG,'testtag-loopend');
						val.querySelector('.js-categorytag-tag').insertAdjacentHTML('beforeend', TAG);
					}
				});
			});
		}
		if(document.querySelectorAll('.js-categorytagEN').length) {
			document.querySelectorAll('.js-categorytagEN').forEach(function(val, index){
				fetch(getJSONfilescategorytag(val.querySelector('.rt_cf_ng_news_key').textContent)).then(function (data) {
					return data.json();
				}).then(function (data) {
					console.log(data,'data');
					let CATEGORY = '';
					CATEGORY = `<span>${data.rt_bn_news_info_json[0].cf_n_category.cf_nc_name_en}</span>`;
					val.querySelector('.js-categorytagEN-label').insertAdjacentHTML('beforeend', CATEGORY);
					let TAG = '';
					if (data.rt_bn_news_info_json[0].cf_n_tags) {
						data.rt_bn_news_info_json[0].cf_n_tags.forEach(function(tagval, tagindex){
							// console.log(tagval, tagindex,'test');
							TAG += `<li><span class="__tag">${tagval.cf_nt_name_d_en}</span></li>`;
							// console.log(TAG,'testtag');
						});
						// console.log(TAG,'testtag-loopend');
						val.querySelector('.js-categorytagEN-tag').insertAdjacentHTML('beforeend', TAG);
					}
				});
			});
		}
	};

	removeElmIfEmpty();
	removeBlockIfListEmpty();
	categorytagJSON();

})(window.FUNCTIONS);
